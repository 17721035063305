import { useState, useEffect } from 'react';
import { useSearchParams, createSearchParams, useNavigate, Outlet } from 'react-router-dom';

import {
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
  Button,
} from '@edx/paragon';
import PropTypes from 'prop-types';
import {
  expOptions,
  fieldsTable,
  radioBtnValuesMap,
  additlClassCharLimit,
} from './data/constants';
import { postSurveyData } from '../../../services/survey';
import { validateEmail, validateCharLimit } from '../../../utils/formValidation';

import './survey-form-panel.scss';
import dlaiLogo from '../../../assets/dlai-logo.png';

const ExpRadios = ({
  expOptions = [],
  handleExpChange = () => console.log('handleExpChange'),
  expValue = '0',
}) => {
  return (
    <Form.RadioSet
      as={Col}
      className="offset-0 offset-md-1"
      name="exp-options"
      onChange={handleExpChange}
      value={expValue}
    >
      {
        expOptions
          .sort((opa, opb) => opa.level - opb.level)
          .map(({ levelDes, level }) => (
            <Form.Radio
              key={levelDes}
              value={level.toString()}
              className="my-2"
              controlClassName="custom-radio-style"
              labelClassName="custom-radio-label-style"
            >
              {levelDes}
            </Form.Radio>
          ))
      }
    </Form.RadioSet>
  );
};

ExpRadios.propTypes = {
  expOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleExpChange: PropTypes.func.isRequired,
  expValue: PropTypes.string.isRequired,
};

const CustomFloatingLabel = ({
  children = '',
}) => {
  return (
    <Form.Label
      className="custom-floating-label-style"
    >
      {children}
    </Form.Label>
  );
};

CustomFloatingLabel.propTypes = {
  children: PropTypes.string,
};

const RequiredFieldSymbol = () => (<span className="custom-required-field-symbol-style">&nbsp;&nbsp;*</span>);
const FieldBulletSymbol = () => (<span className="custom-field-bullet-symbol">&#9679;&nbsp;&nbsp;</span>);

const SurveyFormPanel = () => {
  const [lidValue, setLidValue] = useState('');
  const [firstNameValue, setFirstNameValue] = useState('');
  const [lastNameValue, setLastNameValue] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const [courseValue, setCourseValue] = useState('');
  const [expValue, setExpValue] = useState('0');
  const [isFirstTimeValue, setIsFirstTimeValue] = useState('');
  const [additlClassValue, setAdditlClassValue] = useState('');
  const [newsSubsValue, setNewsSubsValue] = useState(true);

  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [isEmailValueValid, setIsEmailValueValid] = useState(false);
  const [isAdditlClassValueValid, setIsAdditlClassValueValid] = useState(true);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleChange = (value, stateSetter) => {
    stateSetter(value);
  };

  const handleSubmitClick = () => {
    setSubmitEnabled(false);

    const payload = {
      [fieldsTable['lid'].payloadFieldName]: lidValue,
      [fieldsTable['formFirstName'].payloadFieldName]: firstNameValue,
      [fieldsTable['formLastName'].payloadFieldName]: lastNameValue,
      [fieldsTable['formEmail'].payloadFieldName]: emailValue,
      [fieldsTable['formCourse'].payloadFieldName]: courseValue,
      [fieldsTable['formExp'].payloadFieldName]: parseInt(expValue, 10),
      [fieldsTable['formIsFirstTime'].payloadFieldName]: radioBtnValuesMap[isFirstTimeValue],
      [fieldsTable['formAdditlClass'].payloadFieldName]: additlClassValue,
      [fieldsTable['formNewsSubsValue'].payloadFieldName]: newsSubsValue,
    };

    postSurveyData(payload)
      .then((result) => {
        const { success } = result;
        if (success) {
          navigate({
            pathname: 'thank-you',
            search: createSearchParams({
              'course_name': courseValue,
            }).toString(),
            replace: true,
          });
        }
      }).catch(error => {
        setSubmitEnabled(true);
        console.log(error);
        navigate({
          pathname: 'error',
          search: createSearchParams({
            'course_name': courseValue,
          }).toString(),
        });
      });
  };

  useEffect(() => {
    const courseNameFromUrl = searchParams.get('course_name');
    const lidFromUrl = searchParams.get('lid');
    setCourseValue(courseNameFromUrl || '');
    setLidValue(lidFromUrl || '');
  }, []);

  useEffect(() => {
    if (validateEmail(emailValue)) {
      setIsEmailValueValid(true);
    } else if (!emailValue) {
      setIsEmailValueValid(true);
    } else {
      setIsEmailValueValid(false);
    }
  }, [emailValue]);

  useEffect(() => {
    if (validateCharLimit(additlClassValue.length, additlClassCharLimit)) {
      setIsAdditlClassValueValid(true);
    } else {
      setIsAdditlClassValueValid(false);
    }
  }, [additlClassValue]);

  useEffect(() => {
    if (firstNameValue && lastNameValue && emailValue && courseValue && expValue !== '0' && isFirstTimeValue && isEmailValueValid && isAdditlClassValueValid) {
      setSubmitEnabled(true);
    } else {
      setSubmitEnabled(false);
    }
  }, [firstNameValue, lastNameValue, emailValue, courseValue, expValue, isFirstTimeValue, isEmailValueValid, isAdditlClassValueValid]);

  const formRowClassName = 'align-items-center p-2 px-5';

  return (
    <Container className="h-100 bg-brand-100 pt-4 pb-6 survey-form-panel">
      <Container size="xl">
        <Row className="justify-content-start">
          <Col xs={6} sm={4} md={2}>
            <img src={dlaiLogo} alt="dlai logo" className="custom-image-style" />
          </Col>
        </Row>
      </Container>
      <Container size="md" className="bg-white box-shadow-centered-1 rounded mt-4.5 py-5">
        <Row className={`${formRowClassName} justify-content-start mb-4`}>
          <Col xs={12} md={8}>
            <h1 className="custom-h1-style">
              Learner Intake Survey
            </h1>
          </Col>
        </Row>
        <Row className={`${formRowClassName} justify-content-center`}>
          <Form.Group as={Col} xs={12} md={6} controlId="formFirstName" className="normalize-form-group-style">
            <Form.Control
              className="mb-2 custom-form-style"
              controlClassName="custom-text-input-style"
              type="text"
              floatingLabel="First name"
              value={firstNameValue}
              onChange={(e) => { handleChange(e.target.value, setFirstNameValue); }}
              trailingElement={(<RequiredFieldSymbol />)}
            />
          </Form.Group>
          <Form.Group as={Col} xs={12} md={6} controlId="formLastName" className="normalize-form-group-style">
            <Form.Control
              className="mb-2 custom-form-style"
              controlClassName="custom-text-input-style"
              type="text"
              floatingLabel="Last name"
              value={lastNameValue}
              onChange={(e) => { handleChange(e.target.value, setLastNameValue); }}
              trailingElement={(<RequiredFieldSymbol />)}
            />
          </Form.Group>
        </Row>
        <Row className={`${formRowClassName} justify-content-center justify-content-md-start`}>
          <Form.Group as={Col} xs={12} md={8} controlId="formEmail" className="normalize-form-group-style" isInvalid={!isEmailValueValid}>
            <Form.Control
              className="custom-form-style"
              controlClassName="custom-text-input-style"
              type="email"
              floatingLabel="Email"
              value={emailValue}
              onChange={(e) => { handleChange(e.target.value, setEmailValue); }}
              trailingElement={(<RequiredFieldSymbol />)}
            />
            <Form.Control.Feedback type="invalid" hasIcon={false}>
              { !isEmailValueValid ? 'The email format is invalid' : <br />}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <hr />
        <Row className={`${formRowClassName} justify-content-center justify-content-md-start`}>
          <Form.Group as={Col} xs={12} md={8} controlId="formCourse" className="normalize-form-group-style">
            <OverlayTrigger
              placement="bottom"
              overlay={(
                <Tooltip variant="light" id="tooltip-course">
                  {courseValue}
                </Tooltip>
              )}
            >
              <Form.Control
                type="text"
                className="custom-form-style"
                controlClassName="bg-brand-100 custom-text-input-style readOnly"
                floatingLabel={(
                  <CustomFloatingLabel>
                    Course name
                  </CustomFloatingLabel>
                )}
                value={courseValue}
                readOnly
              />
            </OverlayTrigger>
          </Form.Group>
        </Row>
        <hr />
        <Row className={`${formRowClassName}`}>
          <Form.Group as={Col} xs={12} controlId="formExp">
            <Row className="justify-content-center justify-content-sm-start">
              <Form.Label>
                <FieldBulletSymbol />What is your current level of experience? Please choose the option that best applies:<RequiredFieldSymbol />
              </Form.Label>
            </Row>
            <Row className="my-4">
              <ExpRadios
                expOptions={expOptions}
                handleExpChange={(e) => { handleChange(e.target.value, setExpValue); }}
                expValue={expValue}
              />
            </Row>
          </Form.Group>
        </Row>
        <Row className={`${formRowClassName}`}>
          <Form.Group as={Col} xs={12} controlId="formIsFirstTime" className="justify-content-center">
            <Row className="justify-content-center justify-content-sm-start">
              <Form.Label><FieldBulletSymbol />Is this your first time taking a course from DeepLearning.AI?<RequiredFieldSymbol /></Form.Label>
            </Row>
            <Row className="my-4">
              <Form.RadioSet
                as={Col}
                className="offset-0 offset-md-1"
                name="isFirstTimeOptions"
                onChange={(e) => { handleChange(e.target.value, setIsFirstTimeValue); }}
                value={isFirstTimeValue}
              >
                <Form.Radio className="my-2 border-info-500" controlClassName="custom-radio-style" labelClassName="custom-radio-label-style" value="yes">Yes</Form.Radio>
                <Form.Radio className="my-2" controlClassName="custom-radio-style" labelClassName="custom-radio-label-style" value="no">No</Form.Radio>
              </Form.RadioSet>
            </Row>
          </Form.Group>
        </Row>
        <Row className={`${formRowClassName}`}>
          <Form.Group as={Col} xs={12} controlId="formAdditlClass" className="normalize-form-group-style">
            <Form.Label><FieldBulletSymbol />What are some additional courses you would like to see from DeepLearning.AI?</Form.Label>
            <Form.Control
              className="col col-12 col-md-10 offset-md-1 my-4 custom-form-style"
              controlClassName="custom-text-input-style"
              value={additlClassValue}
              onChange={(e) => { handleChange(e.target.value, setAdditlClassValue); }}
            />
            <Form.Control.Feedback
              type={isAdditlClassValueValid ? 'default' : 'invalid'}
              hasIcon={false}
              className="col col-12 col-md-10 offset-md-1 text-right"
            >
              {`${additlClassValue.length}/${additlClassCharLimit}`}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className={`${formRowClassName}`}>
          <Form.Group as={Col} xs={12} controlId="formNewsSubsValue" className="justify-content-center normalize-form-group-style">
            <Form.Checkbox
              controlClassName="custom-checkbox-style"
              labelClassName="custom-checkbox-label-style"
              checked={newsSubsValue}
              onChange={(e) => { handleChange(e.target.checked, setNewsSubsValue); }}
            >
              Subscribe to receive AI news, events and course updates from DeepLearning.AI!
            </Form.Checkbox>
          </Form.Group>
        </Row>
        <Row className={`${formRowClassName} justify-content-center`}>
          <Col xs={12} sm={8} md={4}>
            <Button disabled={!submitEnabled} variant="brand" className="my-5 custome-button-style" onClick={handleSubmitClick}>Submit</Button>
          </Col>
        </Row>
      </Container>
      <Outlet />
    </Container>
  );
};

SurveyFormPanel.propTypes = {};

export default SurveyFormPanel;
